import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: '404',
    meta: {
      title: '经纪人管理平台',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/404/Index.vue'),
  },

  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/login/Index.vue'),
  },

  {
    path: '/index',
    name: 'index',
    meta: {
      title: '经纪人管理平台',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/index/Index.vue'),
  },

  {
    path: '/brokerData',
    name: 'brokerData',
    meta: {
      title: '经纪人数据',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/broker_data/Index.vue'),
  },

  {
    path: '/wechatOfficialAccountData',
    name: 'wechatOfficialAccountData',
    meta: {
      title: '公众号数据',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/wechat_official_account_data/Index.vue'),
  },

  {
    path: '/prepareData',
    name: 'prepareData',
    meta: {
      title: '进件数据',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/prepare_data/Index.vue'),
  },

  // 
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  to.meta && to.meta.title && (document.title = to.meta.title);
  if (to.meta && to.meta.anonymous) {
    return next()
  }
  next()
});

export default router;
